html,
body,
#app,
#app > div {
  // background: #f5f6f8;
  height: 100%;
  overflow-x: hidden;
}

.notifications i {
  padding-left: 3%;
  padding-right: 3%;
  cursor: pointer;
}

button:disabled i {
  color: lightgray !important ;
}
button i {
  color: #b3bdcc !important ;
}
.hover-blue:hover i,
.hover-blue:hover span {
  color: #007bff !important;
}
.display-6 {
  font-size: 1.5rem;
}
.cursor-pointer {
  cursor: pointer;
}

.text-blue {
  color: #007bff !important;
}

.picky button {
  border-radius: 0.25rem !important;
}

.picky button {
  border: 1px solid #e1e5eb !important;
  box-shadow: none !important;
  outline: none;
  color: #495057;
  font-weight: 300;
  font-size: 0.8125rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

.plants-valid {
  .valid-feedback {
    display: block;
  }
  .picky__input {
    border-color: #17c671 !important;
    box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1) !important;
  }

  .picky button:focus {
    border-color: #17c671 !important;
  }
}

.plants-invalid {
  .invalid-feedback {
    display: block;
  }
  .picky__input {
    border-color: #c4183c !important;
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1) !important;
  }

  .picky button:focus {
    border-color: #c4183c !important;
  }
}

.picky button:hover {
  border: 1px solid #b3bdcc !important;
}

.picky button:focus {
  border: 1px solid #007bff !important;
}

.picky__dropdown {
  box-shadow: 0 0 15px #b3bdcc;
  color: #495057;
  font-weight: 300;
  font-size: 0.8125rem;
}

.css-15brt4p {
  border: 1px solid #b3bdcc !important;
}

// .multi-custom div:first-child > div {
//   width: 100% !important;
// }

.custom-1 {
  border: 1px solid #b3bdcc;
  border-radius: 5px;
  height: 5rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mh-85 {
  min-height: 85% !important;
}

.h-85 {
  height: 85%;
}

.w-95 {
  max-width: 85% !important;
  width: 100%;
}

.main-navbar {
  box-shadow: 0 0 5px lightgray !important;
}

.nav-item {
  background-color: white;
}
.vh-30 {
  height: 30vw;
}

.response-message {
  position: fixed;
  width: 100%;
  left: 0%;
  font-weight: bold;
  color: white !important;
  text-align: center;
  // height: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  z-index: 1021;
  transition: 0.5s;
  .material-icons {
    font-family: "Material Icons";
    font-weight: bolder;
  }
}
.response-message.show {
  top: 0%;
}
.response-message.hide {
  top: -50%;
}
.response-message.success {
  // &::before {
  //   font-family: "Font Awesome 5 Free";
  //   content: "\f071";
  //   padding-right: 1%;
  //   font-weight: bold;
  //   color: white !important;
  // }
  background: #17c671;
}
.response-message.fail {
  background: #c4183c;
}

.modal-width {
  width: 344px;
  
  .modal-content{
    border-radius: 112px;
  }
  .modal-body-name{
    font-weight: bold;
  }
  .modal-body-message{
    font-size: small;
  }
  .modal-body-discard{
    font-size: small;
    color: red;
  }
  
}